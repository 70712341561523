import React from "react"

export default function Banner(props) {
  return (
    <section className={"section banner has-text-centered"} style={{backgroundImage: `url(${props.imageUrl})`}}>
      <div className="container" style={{height: "100%", position:"relative", zIndex:"3"}}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
          <div>
            {props.preheading &&
              <p className="preheading is-size-3 ">{props.preheading}</p>
            }
            <h1 className="title is-size-1 has-text-white">{props.title}</h1>
            {props.button &&
              <a className="button green half transition" href={props.link}>{props.button}</a>
            }
          </div>
        </div>
      </div>
    </section>
  )
}